/* .App {
  text-align: center;
} */


/* jjj */

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 427px;
  background: #ffffff;
  border-radius: 24px;
  padding: 40px;
}
.header {
  margin-bottom: 8px;
  font-family: semibold;
  font-size: 20px;
  /* font-style: normal; */
  font-weight: 300;
  line-height: 125%; /* 17.5px */
  color: #212121;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 12px;
  padding-bottom: 4px;
}
.subHeader {
  margin-bottom: 8px;
  font-family: semibold;
  font-size: 14px;
  /* font-style: normal; */
  font-weight: 300;
  line-height: 125%; /* 17.5px */
  color: #212121;
}

.text {
  font-size: 14px;
  font-family: light;
  /* font-weight: 300; */
  line-height: 125%; /* 17.5px */
  color: #4a4a4a;
}

.crossContainer {
  position: absolute;
  right: 0;
  top: 0px;
  height: 40px;
  width: 40px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(28, 26, 23, 0.75);
  backdrop-filter: blur(6px);
}
.crossImg {
  height: 20px;
}
